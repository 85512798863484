/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiStarLine, RiTeamLine, RiLightbulbLine, RiBriefcaseLine } from "react-icons/ri"

const CandidateEvaluationScorecardDownloadPage = () => {
  
  // Evaluation criteria by category
  const evaluationCriteria = {
    technicalSkills: [
      "Technical knowledge relevant to role",
      "Proficiency with required tools/technologies",
      "Problem-solving approach",
      "Technical communication clarity",
      "Adaptability to new technologies"
    ],
    experience: [
      "Relevant work experience",
      "Industry knowledge",
      "Track record of achievements",
      "Project management experience",
      "Leadership experience (if applicable)"
    ],
    culturalFit: [
      "Alignment with company values",
      "Communication style",
      "Team collaboration approach",
      "Adaptability and flexibility",
      "Growth mindset"
    ],
    softSkills: [
      "Communication skills",
      "Collaboration and teamwork",
      "Critical thinking",
      "Time management",
      "Emotional intelligence"
    ]
  }
  
  // Rating scale for evaluation
  const ratingScale = [
    { score: 5, label: "Outstanding", description: "Exceptional match, exceeds all requirements" },
    { score: 4, label: "Strong", description: "Above average, meets requirements very well" },
    { score: 3, label: "Satisfactory", description: "Meets basic requirements adequately" },
    { score: 2, label: "Needs Development", description: "Below expectations, significant gaps exist" },
    { score: 1, label: "Unsatisfactory", description: "Does not meet requirements" }
  ]
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Candidate Evaluation Scorecard | Standardized Hiring Assessment Tool"
        description="Download our structured candidate evaluation scorecard for consistent, objective hiring decisions. Compare candidates fairly across key competencies and attributes."
        keywords={[
          "candidate evaluation scorecard",
          "hiring assessment template",
          "interview scoring matrix",
          "job applicant evaluation form",
          "recruitment evaluation tool",
          "candidate rating system",
          "objective hiring criteria",
          "standardized interview assessment",
          "structured hiring template",
          "recruitment decision matrix"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/recruitment-hiring" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Recruitment & Hiring Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Candidate Evaluation Scorecard
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A standardized evaluation form to objectively assess and compare job candidates across key competencies.
              This scorecard helps eliminate bias and ensure consistent, data-driven hiring decisions.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Use a Standardized Evaluation Scorecard?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              Standardized candidate evaluation helps eliminate unconscious bias, promotes objectivity, and ensures 
              all candidates are assessed on the same criteria. This scorecard provides a structured framework to 
              evaluate candidates across essential competencies, making it easier to compare candidates fairly and 
              make data-driven hiring decisions.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Objective Comparison",
                  description: "Evaluate all candidates against the same criteria to ensure fair comparisons",
                  icon: <RiCheckLine />
                },
                {
                  title: "Reduce Bias",
                  description: "Structured evaluation reduces the impact of unconscious bias in hiring decisions",
                  icon: <RiUserLine />
                },
                {
                  title: "Consistent Process",
                  description: "Maintain a consistent approach across all interviews and hiring managers",
                  icon: <RiBriefcaseLine />
                },
                {
                  title: "Data-Driven Decisions",
                  description: "Base hiring decisions on objective data rather than gut feelings",
                  icon: <RiStarLine />
                }
              ].map((benefit, index) => (
                <div key={index} sx={{
                  bg: 'background',
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    {benefit.icon}
                  </div>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2
                  }}>
                    {benefit.title}
                  </h3>
                  <p sx={{
                    fontSize: '0.9rem',
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Scorecard Template Preview
            </h2>
            
            <div sx={{
              bg: 'white',
              p: [3, 4],
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              {/* Candidate Information */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Candidate Information
                </h3>
                
                <div sx={{ 
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3
                }}>
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Candidate Name:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }}></div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Position Applied For:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }}></div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Interviewer Name:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }}></div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Interview Date:</div>
                    <div sx={{ 
                      height: '2rem',
                      border: '1px solid',
                      borderColor: 'muted',
                      borderRadius: '4px',
                      p: 2
                    }}></div>
                  </div>
                </div>
              </div>
              
              {/* Rating Scale */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Rating Scale
                </h3>
                
                <table sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '0.9rem'
                }}>
                  <thead>
                    <tr sx={{
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <th sx={{ p: 2, textAlign: 'left', width: '15%' }}>Score</th>
                      <th sx={{ p: 2, textAlign: 'left', width: '25%' }}>Rating</th>
                      <th sx={{ p: 2, textAlign: 'left', width: '60%' }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingScale.map((rating, index) => (
                      <tr 
                        key={index}
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'muted',
                          bg: index % 2 === 0 ? 'white' : '#f8f9fa'
                        }}
                      >
                        <td sx={{ p: 2, fontWeight: 600 }}>
                          {rating.score}
                        </td>
                        <td sx={{ p: 2 }}>
                          {rating.label}
                        </td>
                        <td sx={{ p: 2 }}>
                          {rating.description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              {/* Example Evaluation Section */}
              <div sx={{ mb: 4 }}>
                <h3 sx={{ 
                  fontSize: '1.2rem', 
                  fontWeight: 600,
                  mb: 3,
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  Technical Skills Evaluation
                </h3>
                
                <table sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '0.9rem',
                  mb: 3
                }}>
                  <thead>
                    <tr sx={{
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <th sx={{ p: 2, textAlign: 'left', width: '60%' }}>Criteria</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>1</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>2</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>3</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>4</th>
                      <th sx={{ p: 2, textAlign: 'center' }}>5</th>
                      <th sx={{ p: 2, textAlign: 'center', width: '15%' }}>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {evaluationCriteria.technicalSkills.map((criteria, index) => (
                      <tr 
                        key={index}
                        sx={{
                          borderBottom: '1px solid',
                          borderColor: 'muted',
                          bg: index % 2 === 0 ? 'white' : '#f8f9fa'
                        }}
                      >
                        <td sx={{ p: 2 }}>
                          {criteria}
                        </td>
                        {[1, 2, 3, 4, 5].map(score => (
                          <td key={score} sx={{ p: 2, textAlign: 'center' }}>
                            <div sx={{
                              width: '20px',
                              height: '20px',
                              border: '1px solid',
                              borderColor: 'muted',
                              borderRadius: '50%',
                              mx: 'auto'
                            }}></div>
                          </td>
                        ))}
                        <td sx={{ p: 2, textAlign: 'center' }}>
                          <div sx={{ 
                            height: '1.8rem',
                            border: '1px solid',
                            borderColor: 'muted',
                            borderRadius: '4px'
                          }}></div>
                        </td>
                      </tr>
                    ))}
                    <tr sx={{
                      borderBottom: '1px solid',
                      borderColor: 'muted',
                      bg: 'muted',
                      fontWeight: 600
                    }}>
                      <td sx={{ p: 2, textAlign: 'right' }} colSpan={6}>
                        Section Average:
                      </td>
                      <td sx={{ p: 2, textAlign: 'center' }}>
                        <div sx={{ 
                          height: '1.8rem',
                          border: '1px solid',
                          borderColor: 'muted',
                          borderRadius: '4px',
                          bg: 'white'
                        }}></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <div>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Comments/Observations:</div>
                  <div sx={{ 
                    height: '5rem',
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '4px',
                    p: 2
                  }}></div>
                </div>
              </div>
              
              <p sx={{ 
                fontSize: '0.9rem', 
                fontStyle: 'italic',
                textAlign: 'center',
                color: 'text',
                opacity: 0.8
              }}>
                Note: The complete template includes evaluation sections for Experience, Cultural Fit, and Soft Skills,
                as well as an overall assessment section and hiring recommendation.
              </p>
            </div>
          </section>
          
          {/* Best Practices */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Best Practices for Candidate Evaluation
            </h2>
            
            <ul sx={{
              pl: 4,
              mb: 4
            }}>
              {[
                "Define job-specific criteria before beginning the interview process",
                "Train all interviewers on how to use the evaluation scorecard consistently",
                "Complete evaluations immediately after each interview while impressions are fresh",
                "Focus on evidence and specific examples rather than general feelings",
                "Include diverse interviewers to get multiple perspectives",
                "Compare candidates based on scores across key competencies",
                "Use evaluation data to improve your hiring process over time",
                "Document specific examples to support your ratings"
              ].map((practice, index) => (
                <li key={index} sx={{ mb: 2, lineHeight: 1.5 }}>
                  {practice}
                </li>
              ))}
            </ul>
          </section>
          
          {/* Closing CTA */}
          <section>
            <div sx={{
              bg: 'primary',
              color: 'white',
              p: 4,
              borderRadius: '8px',
              textAlign: 'center'
            }}>
              <h2 sx={{ 
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3 
              }}>
                Ready to Improve Your Hiring Process?
              </h2>
              
              <p sx={{ mb: 4, lineHeight: 1.6, fontSize: '1.1rem' }}>
                Download the complete Candidate Evaluation Scorecard with all sections and additional guidance.
              </p>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 4,
                  borderRadius: '6px',
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.2)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template
              </Link>
            </div>
          </section>
        </div>
      </div>
      
      {/* Print Styles */}
      <style jsx="true">{`
        @media print {
          .print-hide {
            display: none !important;
          }
          body {
            font-size: 12pt;
          }
          a {
            text-decoration: none !important;
            color: #000 !important;
          }
        }
      `}</style>
    </Layout>
  )
}

export default CandidateEvaluationScorecardDownloadPage 